.server-room-status-details__container {
  display: grid;
  grid-template-areas:
    "a a a a"
    "b b b b"
    "c c c c";
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 20px;
  gap: 20px;
}

.server-room-status-details__form-container {
  display: grid;
  grid-template-areas:
    "a a a a"
    "c c c c";
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 3fr;
  margin: 20px;
  gap: 20px;
  height: 100%;
}

.server-room-status-details__img-container {
  grid-area: a;
  justify-self: center;
  width: 100%;
  position: relative;
}

.server-room-status-details__ac-status-list {
  background-color: black;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  grid-area: b;
  width: 100%;
  overflow-x: scroll;
}

.server-room-status-details__room-details {
  display: grid;
  grid-area: c;
  width: 100%;
  gap: 20px;
}

.server-room-status-details__form {
  display: grid;
  grid-area: c;
  width: 100%;
  height: 100%;
  gap: 20px;
}

@media all and (orientation: landscape) {
  .server-room-status-details__container {
    grid-template-areas:
      "b b b b b"
      "c c c c c";
    grid-template-columns: 1fr 1fr 1fr 1fr 3fr;
    grid-template-rows: 1fr 4fr;
  }

  .server-room-status-details__form-container {
    display: grid;
    grid-template-areas: "c c c c";
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 20px;
    gap: 20px;
  }

  .server-room-status-details__img-container {
    display: none;
  }

  .hidden-when-landscape {
    display: none;
  }
}
