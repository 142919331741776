.common-numbered-text-field
  .MuiOutlinedInput-root
  .MuiOutlinedInput-notchedOutline {
  border-color: white !important;
  border: solid 1px white;
  border-radius: 24px;
  width: 100%;
  height: 90%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
