.server-room-status__container {
  display: grid;
  grid-template-areas:
    "a a a a"
    "d d d d"
    "b b b b"
    "c c c c";
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 20px;
  gap: 20px;
}

.server-room-status__img-container {
  grid-area: a;
  justify-self: center;
  width: 100%;
  position: relative;
}

.server-room-status__detail-button {
  grid-area: d;
  text-decoration: underline;
  justify-self: end;
}

.server-room-status__ac-status-list {
  background-color: black;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  grid-area: b;
  width: 100%;
  overflow-x: scroll;
}

.server-room-status__room-ac-settings {
  display: grid;
  grid-area: c;
  width: 100%;
  gap: 20px;
}

@media all and (orientation: landscape) {
  .server-room-status__container {
    grid-template-areas:
      "a a a a c "
      "d d d d c"
      "b b b b c";
    grid-template-columns: 1fr 1fr 1fr 1fr 4fr;
    grid-template-rows: 5fr 0.1fr 1.5fr;
    gap: 10px;
  }

  .server-room-status__ac-status-list {
    padding: 8px;
  }

  .hidden-when-landscape {
    display: none;
  }
}
