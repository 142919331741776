.server-room-sensor-bar__container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 12px;
}

.server-room-sensor-bar-item__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: black;
  padding: 12px;
  border-radius: 12px;
}

.server-room-sensor-bar-item__label {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.server-room-sensor-bar-item__measurement {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.server-room-sensor-bar-item__measurement-value {
  font-size: 1.75rem;
  line-height: 1.75rem;
}

.server-room-sensor-bar-item__measurement-value-temperature {
  color: white;
}

.server-room-sensor-bar-item__measurement-value-airquality {
  color: #a3a1fb;
}

.server-room-sensor-bar-item__measurement-value-humidity {
  color: #56d9fe;
}

.server-room-sensor-bar-item__measurement-value-co2 {
  color: #ffa726;
}

.server-room-sensor-bar-item__img {
  margin: 16px;
  width: 40px;
}

@media all and (orientation: landscape) {
  .server-room-sensor-bar__container {
    display: block;
    background-color: black;
    border-radius: 16px;
  }

  .server-room-sensor-bar-item__container {
    display: grid;
    justify-items: stretch;
    grid-template-columns: 2fr 1fr;
  }

  .server-room-sensor-bar-item__label-name {
    font-size: 1.2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .server-room-sensor-bar-item__sublabel-name {
    display: none;
  }

  .server-room-sensor-bar-item__measurement {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .server-room-sensor-bar-item__measurement-unit {
    font-size: 1.2rem;
  }
}
