.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.schedule-form-page__schedule-title {
  display: flex;
  flex-direction: row;
  justify-content: "start";
  align-items: center;
  gap: 8px;
}

.schedule-form-page__container {
  display: flex;
  flex-direction: row;
  justify-content: "start";
  align-items: center;
  gap: 16px;
  margin: 5%;
}

@media all and (orientation: landscape) {
  .schedule-form-page__schedule-title {
    display: none;
  }

  .schedule-form-page__container {
    margin: 0%;
  }
}
