.server-room-login-page__container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-top: 10%;
  height: 100%;
}

@media all and (orientation: landscape) {
  .server-room-login-page__container {
    margin-top: 5%;
  }
}
