.server-room-header__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 64px;
  padding-bottom: 24px;
  gap: 16px;
  width: 100%;
}

@media all and (orientation: landscape) {
  .server-room-header__title {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
