.server-room-status__divider {
  width: 90%;
  margin: 40px;
}

@media all and (orientation: landscape) {
  .server-room-status__divider {
    width: 90%;
    margin: 5px;
  }
}
