.room-ac-settings__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

@media all and (orientation: landscape) {
  .room-ac-settings__server-room-status {
    display: none;
  }

  .room-ac-settings__container {
    grid-template-columns: 1fr;
    gap: 4px;
  }

  .room-ac-settings__ac-control {
    width: 100%;
  }
}
