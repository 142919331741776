.interlock-form-page__interlock-title {
  display: flex;
  flex-direction: row;
  justify-content: "start";
  align-items: center;
  gap: 8px;
}

.interlock-form-page__container {
  display: flex;
  flex-direction: row;
  justify-content: "start";
  align-items: center;
  gap: 16px;
  margin: 5%;
}

@media all and (orientation: landscape) {
  .interlock-form-page__interlock-title {
    display: none;
  }

  .interlock-form-page__container {
    margin: 0%;
  }
}
