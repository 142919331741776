.server-room-home__container {
  width: calc(100% - 64px);
  height: 100%;
  gap: 32px 32px;
  margin-left: 32px;
  margin-right: 32px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas:
    "a a b b"
    "c c c c"
    "d d d d";
}

.server-room-home__title {
  font-size: 1.5rem;
}

.server-room-home__settings {
  grid-area: a;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.server-room-home__device-summary {
  grid-area: b;
  justify-self: end;
  border-radius: 16;
}

.server-room-home__sensor {
  grid-area: c;
  width: 100%;
}

.server-room-home__server-room-list {
  grid-area: d;
  width: 100%;
}

@media all and (orientation: landscape) {
  .server-room-home__container {
    grid-template-columns: 1fr 1fr 1.5fr 1.5fr;
    grid-template-areas:
      "a a d d"
      "c c d d"
      "b b d d";
    gap: 16px 16px;
  }

  .server-room-home__title {
    font-size: 1.2rem;
  }

  .server-room-home__device-summary {
    grid-area: b;
    justify-self: center;
  }
}
