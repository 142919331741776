@import url(https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap);
html, body, #root, .App {
  background: rgb(64,64,64);
  background: linear-gradient(0deg, rgba(64,64,64,1) 0%, rgba(28,28,28,1) 25%, rgba(28,28,28,1) 75%, rgba(46,46,46,1) 100%);
  background-repeat: no-repeat;
  color: white;
  margin: 0;
  overscroll-behavior-x: none;
}

html, body, #root, .App {
  overflow-x: hidden;
  /* overflow-y: hidden; */
  width: 100%;
  height: 100%;
  min-height: -webkit-fill-available;
}

body > iframe {
  display: none;
}

* {
  font-family: 'Kanit', sans-serif !important;
}

.common-inactive {
  opacity: 0.3;
  pointer-events: none;
}

.san-serif, .san-serif * {
  font-family: sans-serif !important;
}

.container {
  /* margin: auto; */
  width: 100%;
  /* max-width: 1500px; */
  padding: 28px;
  height: 100%;
}

.container-low-padding {
  padding-top: 10px;
  padding-bottom: 10px;
}

.web-grid-container {
  display: grid;
  grid-template-columns: 24% auto;
  grid-column-gap: 24px;
  -webkit-column-gap: 24px;
          column-gap: 24px;
}

.panel-inner-grid {
  display: grid;
  grid-template-columns: auto 400px;
  grid-column-gap: 24px;
  -webkit-column-gap: 24px;
          column-gap: 24px;
  grid-row-gap: 24px;
  row-gap: 24px;
  height: 100%;
}

input[type="color"] {
  -webkit-appearance: none;
  border: none;
  padding: 0;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
}

.center-y{
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

@-webkit-keyframes slide-text {
  from {
    left: calc(var(--textLength) * -1px);
  }

  to {
    left: 100%
  }
}

@keyframes slide-text {
  from {
    left: calc(var(--textLength) * -1px);
  }

  to {
    left: 100%
  }
}

.auto-height {
  height: auto !important;
}

@media screen and (max-width: 1199px) {
  /* .modal {
    align-items: flex-end;
  } */

  .modal.modal-center-only {
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    max-width: 900px;
    width: 100%;
  }
}

.modal-content {
  width: 900px;
}

.dialog-content {
  max-height: calc(100vh - 50px);
  overflow-y: auto;
}

@media screen and (max-width: 1199px) {
  .modal-content {
    width: 100%;
  }
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

.input-nofocus input:focus, .input-nofocus select:focus {
  outline: none;
}

.input-text input, .input-text option {
  font-size: 16px;
}

* {
  box-sizing: border-box;
}

/*
========= HOME PAGE =========
*/

.homepage-why-grid {
  margin-top: 48px;
  display: grid;
  grid-template-columns: 50% 50%;
}

@media screen and (min-width: 1200px) {
  .homepage-why-grid {
    grid-template-columns: 25% 25% 25% 25%;
  }
}

@media screen and (max-width: 1399px) {
  .body-container {
    zoom: 0.85;
  }

  .container-low-padding {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .reset-zoom {
    zoom: 1.18;
  }
}

@media screen and (max-width: 1200px) {
  .body-container {
    zoom: 0.85;
    height: 100%;
  }
}

.no-zoom {
  /* zoom: 1 !important;  */
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 111vw !important;
  }
}

.hover-pointer:hover {
  cursor: pointer;
}

.device-status-table {
  border-collapse: collapse;
}

.device-status-table thead {
  border-bottom: 1px solid #ccc;
}

.device-status-table td, .device-status-table th {
  padding-top: 6px;
  padding-bottom: 6px;
}

.device-status-table th {
  padding-top: 0px;
}

.th-left th {
  text-align: left;
}

.text-white * {
  color: white;
}

.td-padding td {
  padding-top: 4px;
  padding-bottom: 4px;
}

.td-border-bottom td {
  border-bottom: 1px solid gray;
  padding-top: 8px;
  padding-bottom: 8px;
}

.device-count-panel {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 16px;
  -webkit-column-gap: 16px;
          column-gap: 16px;

  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 16px;
}

.auto-height {
  height: auto !important;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(32,32,32,0.7);

  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1199px) {
  .modal {
    /* align-items: flex-end; */
  }

  .modal.modal-center-only {
    align-items: center;
  }

  .modal-content {
    max-width: 900px;
    width: 100%;
  }
}

.modal-content {
  width: 900px;
}

.dialog-content {
  max-height: calc(100vh - 50px);
  overflow-y: auto;
}

@media screen and (max-width: 1199px) {
  .modal-content {
    width: 100%;
  }
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.chart-tooltip {
  padding: 12px;
  border-radius: 16px;
  background-color: rgba(128,128,128,0.8);
  z-index: 1000;
}

.chart-value {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  top: 0;
  left: 0;
  font-size: 2rem;
  padding-top: 16;
  pointer-events: none;
}

.text-overflow-ellipsis, .text-overflow-ellipsis * {
  overflow: hidden;
  text-overflow: ellipsis;
}

.speedtest-container {
  width:100%;
  height:0;
  padding-bottom:50%;
  position:relative;
}

.speedtest-iframe {
  border:none;
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  border:none;
  overflow:hidden !important;
}

.vertical-iframe {
  width: 100%;
  height: 100%;
}

.server-room-login-page__container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-top: 10%;
  height: 100%;
}

@media all and (orientation: landscape) {
  .server-room-login-page__container {
    margin-top: 5%;
  }
}

.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.schedule-form-page__schedule-title {
  display: flex;
  flex-direction: row;
  justify-content: "start";
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.schedule-form-page__container {
  display: flex;
  flex-direction: row;
  justify-content: "start";
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
  margin: 5%;
}

@media all and (orientation: landscape) {
  .schedule-form-page__schedule-title {
    display: none;
  }

  .schedule-form-page__container {
    margin: 0%;
  }
}

.server-room-sensor-bar__container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 12px;
  -webkit-column-gap: 12px;
          column-gap: 12px;
}

.server-room-sensor-bar-item__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: black;
  padding: 12px;
  border-radius: 12px;
}

.server-room-sensor-bar-item__label {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.server-room-sensor-bar-item__measurement {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.server-room-sensor-bar-item__measurement-value {
  font-size: 1.75rem;
  line-height: 1.75rem;
}

.server-room-sensor-bar-item__measurement-value-temperature {
  color: white;
}

.server-room-sensor-bar-item__measurement-value-airquality {
  color: #a3a1fb;
}

.server-room-sensor-bar-item__measurement-value-humidity {
  color: #56d9fe;
}

.server-room-sensor-bar-item__measurement-value-co2 {
  color: #ffa726;
}

.server-room-sensor-bar-item__img {
  margin: 16px;
  width: 40px;
}

@media all and (orientation: landscape) {
  .server-room-sensor-bar__container {
    display: block;
    background-color: black;
    border-radius: 16px;
  }

  .server-room-sensor-bar-item__container {
    display: grid;
    justify-items: stretch;
    grid-template-columns: 2fr 1fr;
  }

  .server-room-sensor-bar-item__label-name {
    font-size: 1.2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .server-room-sensor-bar-item__sublabel-name {
    display: none;
  }

  .server-room-sensor-bar-item__measurement {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .server-room-sensor-bar-item__measurement-unit {
    font-size: 1.2rem;
  }
}

.server-room-home__container {
  width: calc(100% - 64px);
  height: 100%;
  grid-gap: 32px 32px;
  gap: 32px 32px;
  margin-left: 32px;
  margin-right: 32px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas:
    "a a b b"
    "c c c c"
    "d d d d";
}

.server-room-home__title {
  font-size: 1.5rem;
}

.server-room-home__settings {
  grid-area: a;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.server-room-home__device-summary {
  grid-area: b;
  justify-self: end;
  border-radius: 16;
}

.server-room-home__sensor {
  grid-area: c;
  width: 100%;
}

.server-room-home__server-room-list {
  grid-area: d;
  width: 100%;
}

@media all and (orientation: landscape) {
  .server-room-home__container {
    grid-template-columns: 1fr 1fr 1.5fr 1.5fr;
    grid-template-areas:
      "a a d d"
      "c c d d"
      "b b d d";
    grid-gap: 16px 16px;
    gap: 16px 16px;
  }

  .server-room-home__title {
    font-size: 1.2rem;
  }

  .server-room-home__device-summary {
    grid-area: b;
    justify-self: center;
  }
}

.common-inactive {
  opacity: 0.3;
  pointer-events: none;
}

.room-status__sensor-data {
  display: grid;
  grid-template-columns: 10fr 4fr 4fr;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
  background: linear-gradient(grey, grey) 78%/2px 90% no-repeat;
}

.room-status__title {
  font-size: 1.5rem;
}

.room-status__measurement-unit {
  font-size: 1rem;
  color: gray;
}

.room-status__set-value {
  font-size: 1.5rem;
  margin: 0 auto;
}

.room-status__current-value {
  font-size: 1.75rem;
  margin: 0 auto;
  text-align: center;
}

.room-status__current-status {
  font-size: 1rem;
  color: gray;
}

@media all and (orientation: landscape) {
  .room-status__sensor-data {
    background: linear-gradient(grey, grey) 75%/2px 90% no-repeat;
  }
  .room-status__title {
    font-size: 1.2rem;
  }

  .room-status__measurement-unit {
    font-size: 0.8rem;
  }

  .room-status__set-value {
    font-size: 1.2rem;
  }

  .room-status__current-value {
    font-size: 1.2rem;
  }

  .room-status__current-status {
    font-size: 0.8rem;
  }
}

.server-room-status__divider {
  width: 90%;
  margin: 40px;
}

@media all and (orientation: landscape) {
  .server-room-status__divider {
    width: 90%;
    margin: 5px;
  }
}

.room-ac-settings__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  gap: 16px;
}

@media all and (orientation: landscape) {
  .room-ac-settings__server-room-status {
    display: none;
  }

  .room-ac-settings__container {
    grid-template-columns: 1fr;
    grid-gap: 4px;
    gap: 4px;
  }

  .room-ac-settings__ac-control {
    width: 100%;
  }
}

.common-numbered-text-field
  .MuiOutlinedInput-root
  .MuiOutlinedInput-notchedOutline {
  border-color: white !important;
  border: solid 1px white;
  border-radius: 24px;
  width: 100%;
  height: 90%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.server-room-header__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 64px;
  padding-bottom: 24px;
  grid-gap: 16px;
  gap: 16px;
  width: 100%;
}

@media all and (orientation: landscape) {
  .server-room-header__title {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.interlock-form-page__interlock-title {
  display: flex;
  flex-direction: row;
  justify-content: "start";
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.interlock-form-page__container {
  display: flex;
  flex-direction: row;
  justify-content: "start";
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
  margin: 5%;
}

@media all and (orientation: landscape) {
  .interlock-form-page__interlock-title {
    display: none;
  }

  .interlock-form-page__container {
    margin: 0%;
  }
}

.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.schedule-form-page__schedule-title {
  display: flex;
  flex-direction: row;
  justify-content: "start";
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.schedule-form-page__container {
  display: flex;
  flex-direction: row;
  justify-content: "start";
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
  margin: 5%;
}

@media all and (orientation: landscape) {
  .schedule-form-page__schedule-title {
    display: none;
  }

  .schedule-form-page__container {
    margin: 0%;
  }
}

.server-room-status-details__container {
  display: grid;
  grid-template-areas:
    "a a a a"
    "b b b b"
    "c c c c";
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 20px;
  grid-gap: 20px;
  gap: 20px;
}

.server-room-status-details__form-container {
  display: grid;
  grid-template-areas:
    "a a a a"
    "c c c c";
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 3fr;
  margin: 20px;
  grid-gap: 20px;
  gap: 20px;
  height: 100%;
}

.server-room-status-details__img-container {
  grid-area: a;
  justify-self: center;
  width: 100%;
  position: relative;
}

.server-room-status-details__ac-status-list {
  background-color: black;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  grid-area: b;
  width: 100%;
  overflow-x: scroll;
}

.server-room-status-details__room-details {
  display: grid;
  grid-area: c;
  width: 100%;
  grid-gap: 20px;
  gap: 20px;
}

.server-room-status-details__form {
  display: grid;
  grid-area: c;
  width: 100%;
  height: 100%;
  grid-gap: 20px;
  gap: 20px;
}

@media all and (orientation: landscape) {
  .server-room-status-details__container {
    grid-template-areas:
      "b b b b b"
      "c c c c c";
    grid-template-columns: 1fr 1fr 1fr 1fr 3fr;
    grid-template-rows: 1fr 4fr;
  }

  .server-room-status-details__form-container {
    display: grid;
    grid-template-areas: "c c c c";
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 20px;
    grid-gap: 20px;
    gap: 20px;
  }

  .server-room-status-details__img-container {
    display: none;
  }

  .hidden-when-landscape {
    display: none;
  }
}

.server-room-status__container {
  display: grid;
  grid-template-areas:
    "a a a a"
    "d d d d"
    "b b b b"
    "c c c c";
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 20px;
  grid-gap: 20px;
  gap: 20px;
}

.server-room-status__img-container {
  grid-area: a;
  justify-self: center;
  width: 100%;
  position: relative;
}

.server-room-status__detail-button {
  grid-area: d;
  text-decoration: underline;
  justify-self: end;
}

.server-room-status__ac-status-list {
  background-color: black;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  grid-area: b;
  width: 100%;
  overflow-x: scroll;
}

.server-room-status__room-ac-settings {
  display: grid;
  grid-area: c;
  width: 100%;
  grid-gap: 20px;
  gap: 20px;
}

@media all and (orientation: landscape) {
  .server-room-status__container {
    grid-template-areas:
      "a a a a c "
      "d d d d c"
      "b b b b c";
    grid-template-columns: 1fr 1fr 1fr 1fr 4fr;
    grid-template-rows: 5fr 0.1fr 1.5fr;
    grid-gap: 10px;
    gap: 10px;
  }

  .server-room-status__ac-status-list {
    padding: 8px;
  }

  .hidden-when-landscape {
    display: none;
  }
}

/********************************************
	BREAKPOINT WIDTHS
********************************************/
/********************************************
	FONTS
********************************************/
/********************************************
	COLOURS
********************************************/
.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
  transition: all 0.25s ease-in;
  opacity: 0.4;
  filter: alpha(opacity=40);
  position: absolute;
  z-index: 2;
  top: 20px;
  background: none;
  border: 0;
  font-size: 32px;
  cursor: pointer; }
  .carousel .control-arrow:focus, .carousel .control-arrow:hover {
    opacity: 1;
    filter: alpha(opacity=100); }
  .carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
    margin: 0 5px;
    display: inline-block;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    content: ''; }
  .carousel .control-disabled.control-arrow {
    opacity: 0;
    filter: alpha(opacity=0);
    cursor: inherit;
    display: none; }
  .carousel .control-prev.control-arrow {
    left: 0; }
    .carousel .control-prev.control-arrow:before {
      border-right: 8px solid #fff; }
  .carousel .control-next.control-arrow {
    right: 0; }
    .carousel .control-next.control-arrow:before {
      border-left: 8px solid #fff; }

.carousel-root {
  outline: none; }

.carousel {
  position: relative;
  width: 100%; }
  .carousel * {
    box-sizing: border-box; }
  .real-carousel .carousel img {
    width: 100%;
    display: inline-block;
    pointer-events: none; }
  .carousel .carousel {
    position: relative; }
  .carousel .control-arrow {
    outline: 0;
    border: 0;
    background: none;
    top: 50%;
    margin-top: -13px;
    font-size: 18px; }
  .carousel .thumbs-wrapper {
    margin: 20px;
    overflow: hidden; }
  .carousel .thumbs {
    transition: all 0.15s ease-in;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    position: relative;
    list-style: none;
    white-space: nowrap; }
  .carousel .thumb {
    transition: border 0.15s ease-in;
    display: inline-block;
    margin-right: 6px;
    white-space: nowrap;
    overflow: hidden;
    border: 3px solid #fff;
    padding: 2px; }
    .carousel .thumb:focus {
      border: 3px solid #ccc;
      outline: none; }
    .carousel .thumb.selected, .carousel .thumb:hover {
      border: 3px solid #333; }
    .real-carousel .carousel .thumb img {
      vertical-align: top; }
  .carousel.carousel-slider {
    position: relative;
    margin: 0;
    overflow: hidden; }
    .carousel.carousel-slider .control-arrow {
      top: 0;
      color: #fff;
      font-size: 26px;
      bottom: 0;
      margin-top: 0;
      padding: 5px; }
      .carousel.carousel-slider .control-arrow:hover {
        background: rgba(0, 0, 0, 0.2); }
  .carousel .slider-wrapper {
    overflow: hidden;
    margin: auto;
    width: 100%;
    transition: height 0.15s ease-in; }
    .carousel .slider-wrapper.axis-horizontal .slider {
      -ms-box-orient: horizontal;
      display: -moz-flex;
      display: flex; }
      .carousel .slider-wrapper.axis-horizontal .slider .slide {
        flex-direction: column;
        flex-flow: column; }
    .carousel .slider-wrapper.axis-vertical {
      -ms-box-orient: horizontal;
      display: -moz-flex;
      display: flex; }
      .carousel .slider-wrapper.axis-vertical .slider {
        flex-direction: column; }
  .carousel .slider {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
    width: 100%; }
    .carousel .slider.animated {
      transition: all 0.35s ease-in-out; }
  .carousel .slide {
    min-width: 100%;
    margin: 0;
    position: relative;
  }
    .real-carousel .carousel .slide img {
      width: 100%;
      vertical-align: top;
      border: 0; }
    .real-carousel .carousel .slide iframe {
      display: inline-block;
      width: calc(100% - 80px);
      margin: 0 40px 40px;
      border: 0; }
    .carousel .slide .legend {
      transition: all 0.5s ease-in-out;
      position: absolute;
      bottom: 40px;
      left: 50%;
      margin-left: -45%;
      width: 90%;
      border-radius: 10px;
      background: #000;
      color: #fff;
      padding: 10px;
      font-size: 12px;
      text-align: center;
      opacity: 0.25;
      transition: opacity 0.35s ease-in-out; }
  .carousel .control-dots {
    position: absolute;
    bottom: 0;
    margin: 10px 0;
    padding: 0;
    text-align: center;
    width: 100%;
    z-index: 1; }
    @media (min-width: 960px) {
      .carousel .control-dots {
        bottom: 0; } }
    .carousel .control-dots .dot {
      transition: opacity 0.25s ease-in;
      opacity: 0.3;
      filter: alpha(opacity=30);
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
      background: #fff;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      cursor: pointer;
      display: inline-block;
      margin: 0 8px; }
      .carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
        opacity: 1;
        filter: alpha(opacity=100); }
  .carousel .carousel-status {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    font-size: 10px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
    color: #fff; }
  .carousel:hover .slide .legend {
    opacity: 1; }


#router-carousel .carousel-root, #router-carousel .router-section {
  height: 100%;
}

/* Mobile Bug */
/* #router-carousel .slider-wrapper, #router-carousel .slider {
  min-height: 100vh;
} */

#router-carousel .control-arrow {
  display: none;
}

#router-carousel .carousel-status {
  display: none;
}

