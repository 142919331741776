.room-status__sensor-data {
  display: grid;
  grid-template-columns: 10fr 4fr 4fr;
  gap: 20px;
  align-items: center;
  background: linear-gradient(grey, grey) 78%/2px 90% no-repeat;
}

.room-status__title {
  font-size: 1.5rem;
}

.room-status__measurement-unit {
  font-size: 1rem;
  color: gray;
}

.room-status__set-value {
  font-size: 1.5rem;
  margin: 0 auto;
}

.room-status__current-value {
  font-size: 1.75rem;
  margin: 0 auto;
  text-align: center;
}

.room-status__current-status {
  font-size: 1rem;
  color: gray;
}

@media all and (orientation: landscape) {
  .room-status__sensor-data {
    background: linear-gradient(grey, grey) 75%/2px 90% no-repeat;
  }
  .room-status__title {
    font-size: 1.2rem;
  }

  .room-status__measurement-unit {
    font-size: 0.8rem;
  }

  .room-status__set-value {
    font-size: 1.2rem;
  }

  .room-status__current-value {
    font-size: 1.2rem;
  }

  .room-status__current-status {
    font-size: 0.8rem;
  }
}
